

















import { Vue, Component, Prop } from "vue-property-decorator"
import HouseSearch from "~/models/HouseSearch"
import OcularDialog from "~/components/OcularDialog.vue"
import { state } from "@/store"
import snackbar from "~/plugins/snackbar"

@Component({
  components: {
    OcularDialog,
  },
})
export default class NewAlert extends Vue {
  @Prop({ required: true }) houseSearch!: HouseSearch
  @Prop({ default: "Nouvelle alerte" }) title!: string

  name = ""
  rules = [(val: string) => val.length > 0]

  async newAlert() {
    const houseSearch = new HouseSearch().assign({
      ...this.houseSearch,
      id: null,
      title: this.name,
    })
    await houseSearch.save()
    snackbar({
      color: "success",
      content: `L'alerte '${this.name}' a bien été créée !`,
    })
    await state.houseSearches.fetch()
  }
}
